<template>
  <section id="kona-tabs">
    <nav class="navigation-tab" :style="tabsStyle">
      <div
        v-for="(tab, index) in tabsOrdered"
        :key="index"
        class="navigation-tab__item"
        :class="{ active: tab.isActive }"
        @click="selectTab(tab, index)"
      >
        <!-- <KonaHelpTooltip
          :text="tab.infoTooltip"
          position="top"
          v-if="tab.infoTooltip"
        />-->
        <span class="icon">
          <i
            class="material-icons-outlined"
            v-if="tab.iconPack === 'material' && tab.icon"
            >{{ tab.icon }}</i
          >
          <!-- <feather-icon
            v-if="tab.iconPack === 'feather' && tab.icon"
            :icon="tab.icon"
            svgClasses="h-4 w-4 ml-1"
          />-->
          <i
            v-if="tab.iconPack === 'icon-zip'"
            class="
              vs-icon
              notranslate
              icon-scale
              vs-button--icon
              feather
              icon-zap
              null
            "
            style="
              order: 0;
              margin-right: 0px;
              margin-left: 0px;
              font-size: 20px;
              margin-bottom: 2px;
            "
          ></i>
        </span>
        <span class="txt" v-if="tab.name">{{ tab.name }}</span>
      </div>
      <!-- <div class="navigation-tab__overlay" :style="overlayStyle"></div> -->
    </nav>

    <div class="tabs-content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'KonaTabs',
  props: {
    tabsStyle: {
      type: String,
      default: ''
    },
    overlayStyle: {
      type: String,
      default: ''
    },
    iconPack: {
      type: String,
      default: 'material'
    },
    orderById: {
      type: Boolean,
      default: false
    },
    returnSelectedTab: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: []
    }
  },
  // components: {
  //   KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue')
  // },
  created() {
    this.tabs = this.$children
  },
  methods: {
    ...mapMutations('botMaker', ['SET_ACTIVE_CHANNEL_VIEW']),
    // eslint-disable-next-line no-unused-vars
    selectTab(selectedTab, index) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name === selectedTab.name
      })

      if (this.returnSelectedTab) {
        this.$emit(
          'selectedTab',
          selectedTab.$attrs.type || selectedTab.$options.propsData.name
        )
      }
      // this.setAnimation(index)
      // this.$emit('onClick', index)

      // this.SET_ACTIVE_CHANNEL_VIEW('all')
    }
    // setAnimation(index) {
    // const overlay = document.querySelector('.navigation-tab__overlay')
    // overlay.style.left = `${index * 130}px`
    // }
  },
  computed: {
    tabsOrdered() {
      if (this.orderById) {
        const toReturn = this._.orderBy(this.tabs, '$attrs.id')
        return toReturn
      } else {
        return this.tabs
      }
    }
  }
}
</script>

<style lang="scss">
#kona-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  .navigation-tab {
    z-index: 2;
    display: flex;
    align-self: center;
    flex-shrink: 0;
    position: relative;
    max-width: 100%;
    height: 80px;
    // width: 600px;
    user-select: none;
    // background-color: #fafafa;
    // // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    // border: 10px solid #fafafa;
    border: 10px solid transparent;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0px;
      background: transparent;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      flex-shrink: 0;
      cursor: pointer;
      transition: 0.3s;
      position: relative;
      z-index: 2;
      background: white;

      #kona-help-tooltip {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .icon {
        display: block;
        color: #2c2c2c;
        line-height: 1;
        // transition-duration: 0.3s;
        // transform: translate(0, 11px);
        .material-icons {
          font-size: 20px;
        }
      }

      .txt {
        display: block;
        font-weight: 400;
        font-size: 12px;
        color: #2c2c2c;
        // opacity: 0;
        // transition-duration: 0.3s;
        // transform: translate(0, 20px);
        user-select: none;
      }

      &.active {
        // width: 200px;
        color: rgba(var(--vs-primary), 1);
        border-bottom: solid 2px rgba(var(--vs-primary), 1);
        // .icon {
        //   // color: white;
        //   transform: translate(0px, 0px);
        // }
        .txt,
        .icon {
          // color: white;
          color: rgba(var(--vs-primary), 1);
          // opacity: 1;
          // transform: translate(0, 3px);
        }
      }
    }

    // &__overlay {
    //   border-radius: 10px;
    //   background-color: rgba(var(--vs-primary), 0.9) !important;
    //   height: 100%;
    //   width: 200px;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   transition: 0.3s;
    // }
  }
}
</style>
